// Packages
import PropTypes from 'prop-types'

// Components
import Overlay from "../Overlay";

// other
import './styles.css'
import CloseIcon from '../CloseIcon';

const Modal = ({ children, onClose, size } ) => {

    return(
        <>
            <Overlay onClick={() => {onClose(true)}} style={{zIndex: '999'}} />

            <div className={`FormTemplate FormTemplate-${size}`} >
                <CloseIcon 
                    colour='#aaa' 
                    height='25px' 
                    onClick={() => {onClose(true)}}
                    style={{cursor: 'pointer', display: 'block', position: 'absolute', right: '20px', top: '20px'}} 
                    width='25px'
                />
                {children}
            </div>
        </>
    )
}

Modal.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func,
    size: PropTypes.string,
}

Modal.defaultProptypes = {
    children: null,
    onClose: () => {alert('onClose is not set!')},
    size: 'medium'
}

export default Modal;