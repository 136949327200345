// Packages
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Other
import './styles.css';


const Button = ({ className, colour, disabled, formButton, label, type, startIcon, style, onClick, size, target, url }) => {

	return (
		<>
		
		{url ?
				<Link 
					to={url} 
					className={`Button Button--colour-${colour} Button--size-${size} Button--type-${type} ${disabled ? 'disabled' : ''} ${startIcon ? 'startIcon' : ''} ${className}`} 
					style={style}
					target={target}
				>
					{startIcon}
					{label}
				</Link>
			:
		
			<button onClick={() => {

				if( ! disabled ) {

					if ( onClick )
					{
						onClick();
					}
				}

			}} style={style} className={`Button Button--colour-${colour} Button--size-${size} Button--type-${type} ${disabled ? 'disabled' : ''} ${startIcon ? 'startIcon' : ''} ${className}`}>
				{startIcon}
				{label}

			</button>
		}
		</>
	);

}

Button.propTypes = {
	className: PropTypes.string,
	colour: PropTypes.string,
	disabled: PropTypes.bool,
	formButton: PropTypes.bool,
	label: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	size: PropTypes.string,
	startIcon: PropTypes.object,
	style: PropTypes.object,
	type: PropTypes.string,
	url: PropTypes.string,
}

Button.defaultProps = {
	className: '',
	colour: 'default',
	disabled: false,
	formButton: false,
	label: '',
	onClick: () => {},
	size: '',
	startIcon: null,
	style: {},
	type: '',
	url: null
}


export default Button;