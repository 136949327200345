// Other
import 'assets/styles.css';
import Game from 'components/Game';

function App() {

	return (
	<>
		<h1 className='game__title'>Burger, Burger,<br/>Not A Burger</h1>

		<Game/>
    </>
	);
}

export default App;
