// Packages
import React from 'react';
import { motion } from 'framer-motion';

const FlyingObject = ({ item, onClick, onAnimationComplete, speed, }) => {
    
    const animation = {
        initial: {
            x: '50vw',
            y: '110vh',
            opacity: 1
        },
        animate: {
            x: [(Math.random() * 100) - 10 + 'vw', (Math.random() * 100) - 10 + 'vw'],
            y: ['110vh', '-30vh', '110vh'],
            rotate: Math.random() * (1080 - -1080) + -1080,
            transition: { duration: speed, ease: [0.42, 0.0, 0.58, 1.0] }
        },
    };

    return (
        <motion.div
        className="flying-object"
        variants={animation}
        initial="initial"
        animate="animate"
        exit="exit"
        onMouseDown={() => onClick(item)}
        onAnimationComplete={onAnimationComplete}
        style={{ position: 'absolute', top: `${Math.random() * 80}vh` }}
        >
            <img className='flyingObject' src={item.image_url} alt="object" width="50" height="50" style={{width: `${(Math.random() * 200) + 150}px`}}/>
        </motion.div>
    );
};

export default FlyingObject;
