// Packages
import { useState, useEffect, useRef  } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

// Components
import FlyingObject from './FlyingObject';
import Button from './Button';
import Modal from './Modal';

// Assets
import DoubleDecker from '../assets/objects/double-decker.png';
import ChickerBarbeque from '../assets/objects/chicken-barbeque.png';
import FlameGrilled from '../assets/objects/flame-grilled.png';
import Hotdog from '../assets/objects/hotdog.png';
import Burglar from '../assets/objects/burglar.png';
import BLT from '../assets/objects/blt.png';
import Breakfast from '../assets/objects/breakfast.png';
import Baby from '../assets/objects/baby.png';
import Cheeky from '../assets/objects/cheeky-double-patty.png'
import Chocolate from '../assets/objects/chocolate.png'
import Combo from '../assets/objects/combo-deal.png'
import Fake from '../assets/objects/fake.png'
import Falafel from '../assets/objects/falafel.png'
import Fish from '../assets/objects/fish.png'
import HappySpud from '../assets/objects/happy-spud.png'
import Iceberg from '../assets/objects/iceberg.png'
import Interesting from '../assets/objects/interesting.png'
import Lettuce from '../assets/objects/lettuce.png'
import Potato from '../assets/objects/potato.png'
import PurplyPickly from '../assets/objects/purply-pickly.png'
import Salad from '../assets/objects/salad.png'
import Seedy from '../assets/objects/seedy.png'
import Van from '../assets/objects/van.png'
import Veggie from '../assets/objects/veggie.png'
import Bun from '../assets/objects/bun.png'
import Clam from '../assets/objects/clam.png'

import Bite from '../assets/sounds/bite.wav';
import Lose from '../assets/sounds/lose.wav';
import Timeout from '../assets/sounds/timeout.wav';
import Leaderboard from '../assets/sounds/leaderboard.wav';
import KerfuffleShuffle from '../assets/sounds/the-kerfuffle-shuffle.mp3';

// Other
import config from 'other/config';
import Tooltip from './Tooltip';


const objects = [
    { id: 1, image_url: DoubleDecker, message: 'Double Decker!', win: true },
    { id: 2, image_url: ChickerBarbeque, message: 'Chicken and Bacon Barbeque!', win: true },
    { id: 3, image_url: FlameGrilled, message: 'mmMMMMmmm Flame Grilled!', win: true },
    { id: 4, image_url: Hotdog, message: "You twonk! Can't you see that's a hotdog?", win: false },
    { id: 5, image_url: Burglar, message: "That's a BURGLAR, not a burger!", win: false },
    { id: 6, image_url: BLT, message: "BLT burger!", win: true },
    { id: 7, image_url: Breakfast, message: "Breakfast bap anyone?", win: true },
    { id: 8, image_url: Baby, message: "Liddle iddy biddy baby bitesize", win: true },
    { id: 9, image_url: Cheeky, message: "Cheeky double patty", win: true },
    { id: 10, image_url: Chocolate, message: "Chocolate burger - that's just wrong", win: true },
    { id: 11, image_url: Combo, message: "Combo deal!", win: true },
    { id: 12, image_url: Fake, message: "That just looks fake", win: true },
    { id: 13, image_url: Falafel, message: "Is that a falafel burger?", win: true },
    { id: 14, image_url: Fish, message: "Fishy fishy fish fish", win: true },
    { id: 15, image_url: HappySpud, message: "OK, happy face. But it's a spud, you spud.", win: false },
    { id: 16, image_url: Iceberg, message: "Iceberg-er? Still a flunk. That's cold.", win: false },
    { id: 17, image_url: Interesting, message: "Hmmmmm...interesting...", win: true },
    { id: 18, image_url: Lettuce, message: "Lett-us have lettuce? No. Not even iceberg(er)", win: false },
    { id: 19, image_url: Potato, message: "You say potato, and I say...you're wrong.", win: false },
    { id: 20, image_url: PurplyPickly, message: "Pickly Purple", win: true },
    { id: 21, image_url: Salad, message: "So much salad! Any burger in there?", win: true },
    { id: 22, image_url: Seedy, message: "Seedy. Very seedy.", win: true },
    { id: 23, image_url: Van, message: "Burger van man! Not a burger!", win: false },
    { id: 24, image_url: Veggie, message: "Veggie? OK, let you off this time", win: true },
    { id: 25, image_url: Bun, message: "Ha! Gotcha! That's an empty bun", win: false },
    { id: 26, image_url: Clam, message: "Nope, sorry, that's a clam", win: false },
];

const biteSound = new Audio(Bite);
const loseSound = new Audio(Lose);
const timeoutSound = new Audio(Timeout);
const leaderboardSound = new Audio(Leaderboard);
const BackgroundTune = new Audio(KerfuffleShuffle);
// BackgroundTune.loop = true;


const Game = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const result = queryParams.get('result');

    const [score, setScore] = useState(0);
    const [currentObject, setCurrentObject] = useState(null);
    const [gameRunning, setGameRunning] = useState(false);
    const [showGameOverMessage, setShowGameOverMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [showAnimation, setShowAnimation] = useState(false);
    const [loserMessage, setLoserMessage] = useState('');
    const [speed, setSpeed] = useState(3.6);
    const [leaderboard, setLeaderboard] = useState([]);
    const [playerName, setPlayerName] = useState('');
    const [level, setLevel] = useState(1);
    const [showNameForm, setShowNameForm] = useState(true);
    const [nameError, setNameError] = useState('');
    const [resultModalActive, setResultModalActive] = useState(false);
    const [musicPlaying, setMusicPlaying] = useState(true);

    const speedRef = useRef(speed);
    const objectTimeoutRef = useRef(null);
    const speedIntervalRef = useRef(null);

    const allowedCharacters = /^[a-zA-Z0-9\s-]*$/;

    
    useEffect(() => {
        
        // loadLeaderboard();
        
        if (gameRunning) {
            
            startObjectGeneration();
            startSpeedIncrease();
            if(musicPlaying) {
                BackgroundTune.play();
            }

        } else {
            
            stopObjectGeneration();
            stopSpeedIncrease();
            setSpeed(3.6); // Reset speed at the start of each game
            speedRef.current = 3.6;
            setCurrentObject(null);
            BackgroundTune.pause();
            BackgroundTune.currentTime = 0;
        }
        
        return () => {
            stopObjectGeneration();
            stopSpeedIncrease();
        };
        
    }, [gameRunning]);

    useEffect(() => {

        fetchLeaderboard();
    }, []);
    
    const fetchLeaderboard = async () => {

        try {
            const response = await axios.get(config.API_URL + '/leaderboard');
            setLeaderboard(response.data);

        } catch (error) {
            console.error('Error fetching leaderboard:', error);
        }
    };

    useEffect(() => {

        if(result) {

            setResultModalActive(true);
        }
    }, [result]);
    
    
    const startObjectGeneration = () => {

            const generateObject = () => {
            const randomObject = objects[Math.floor(Math.random() * objects.length)];
            setCurrentObject({ ...randomObject, key: Date.now() });
            objectTimeoutRef.current = setTimeout(generateObject, 3000); // Adjust the interval as needed
        };
    
        generateObject();
    };
    
    const stopObjectGeneration = () => {

        if (objectTimeoutRef.current) {

            clearTimeout(objectTimeoutRef.current);
        }
    };

    const startSpeedIncrease = () => {

        let previousSpeed = speedRef.current;
        let isSpeedChanging = false; 

        speedIntervalRef.current = setInterval(() => {

            if (!currentObject && !isSpeedChanging) {

                const newSpeed = Math.max(previousSpeed - 0.3, 0.1); // Decrease speed by 0.2, minimum 0.1
                const speedDelta = previousSpeed - newSpeed;

                isSpeedChanging = true; 

                // Smoothly transition to the new speed
                const transitionInterval = setInterval(() => {

                    const updatedSpeed = Math.max(previousSpeed - 0.1 * Math.sign(speedDelta), newSpeed);
                    setSpeed(Math.round(updatedSpeed * 10) / 10); 

                    if (speedRef.current === newSpeed) {

                        // Stop the transition interval once the target speed is reached
                        clearInterval(transitionInterval);
                        previousSpeed = newSpeed;
                        isSpeedChanging = false;
                        setLevel((prevLevel) => prevLevel + 1);
                    }
                }, 50); // Adjust the transition time as needed
    
                speedRef.current = newSpeed;
                setSpeed(newSpeed);
            }
        }, 15000); // 15000ms = 15 seconds
    };
    
    
    const stopSpeedIncrease = () => {

        if (speedIntervalRef.current) {

            clearInterval(speedIntervalRef.current);
        }
    };

    const handleObjectClick = (item) => {

        if (item.win) {

            setScore(score + 1);
            setMessage(null);
            setMessage(item.message);
            biteSound.play();

        } else {

            setMessage('');
            setLoserMessage(item.message);
            loseSound.play();
            setShowGameOverMessage(true);
            setGameRunning(false);
        }
        setCurrentObject(null); // Remove the object once clicked

        setShowAnimation(false);
        setTimeout(() => setShowAnimation(true), 10);
    };

    const handleAnimationComplete = () => {

        setCurrentObject(null); // Remove the object once the animation completes
    };

    const handleStartRestart = () => {

        setMessage('');
        setScore(0);
        setShowGameOverMessage(false);
        setGameRunning(false);
        setLevel(1);
        setTimeout(() => {
            BackgroundTune.currentTime = 0;
            setGameRunning(true); // Restart the game after a brief pause to reset the state
        }, 100);
    };

    useEffect(() => {

        if(speed < 0.8) {

            setMessage('');
            setLoserMessage("Timed out! That's all you get");
            timeoutSound.play();
            setShowGameOverMessage(true);
            setGameRunning(false);
            setCurrentObject(null);
        }
    }, [speed]);

    const handleSubmitScore = async () => {

        if (playerName) {

            const newLeaderboard = [...leaderboard, { name: playerName, score }];
            newLeaderboard.sort((a, b) => b.score - a.score);
            const top10 = newLeaderboard.slice(0, 10);
            try {
                await axios.post(config.API_URL + '/leaderboard', { leaderboard: top10 });
                setLeaderboard(top10);
                setPlayerName('');
                setShowNameForm(false);
                leaderboardSound.play();
            } catch (error) {
                console.error('Error updating leaderboard:', error);
            }
        }
    };

    const handleNameChange = (e) => {
        const { value } = e.target;

        if (allowedCharacters.test(value)) {
            setPlayerName(value);
            setNameError('');
        } else {
            setNameError('Only letters, numbers, spaces, and hyphens are allowed.');
        }
    };

    return (
        <div className='game'>

            <p className={`message ${showAnimation ? 'show' : ''}`}>{message}</p>

            <div className='game__header'>

                <div className='game__header__stats'>

                    <Tooltip text='Score'>
                        <h1 className='game__burger purple'><span className='game__burger__icon'/>{<>&nbsp;</>}{score}</h1>
                    </Tooltip>

                    <Tooltip text='Level'>
                        <h1 className='game__level purple'><span className='game__level__icon'/>{<>&nbsp;</>}{level}</h1>
                    </Tooltip>
                </div>

                <div className='game__header__buttons'>


                    <Tooltip text={musicPlaying ? 'Music Off' : 'Music On'}>
                        <div className='game__music' onClick={() => {
                            if(musicPlaying) {
                                BackgroundTune.pause();
                                setMusicPlaying(false);
                            }
                            else if(gameRunning) {
                                BackgroundTune.play();
                                setMusicPlaying(true);
                            }
                            else {
                                setMusicPlaying(true);
                            }
                            
                        }}>
                            {! musicPlaying &&
                                <div className='game__music__line' />
                            }
                        </div>
                    </Tooltip>

                    <Button className='responsive' colour='purple' label={gameRunning ? 'Restart' : 'Start'} onClick={handleStartRestart}/>
                </div>

            </div>



            {showGameOverMessage && 

                <Modal onClose={() => {
                    setScore(0);
                    setShowGameOverMessage(false);
                }}>

                    <h3 className='game__loserMessage'>{loserMessage}</h3>

                    <h2 className='game__over'>Game Over</h2>

                    <h4 className='game__score'>You scored: {score}</h4>

                    {showNameForm &&
                    <>
                        {score > leaderboard[leaderboard.length - 1]?.score || leaderboard.length < 10 ? (
                            <div>
                            <h3 className='game__leaderboardMessage'>You made the leaderboard! Enter your name to save your score:</h3>
                            <div className='game__form'>
                                <input
                                    type="text"
                                    value={playerName}
                                    onChange={handleNameChange}
                                />
                                <Button colour='purple' label='Submit' onClick={handleSubmitScore} style={{display: 'inline-block'}}/>
                            </div>
                            {nameError && <p className='red'>{nameError}</p>}
                            </div>
                        ) : (
                            <h3>You didn't make it to the leaderboard. You suck.</h3>
                        )}
                    </>}

                    <div className='game__icons'>
                        <span>Share your score: </span>
                        {/* <div class="fb-share-button" data-href="https://burgerburgernotaburger.com" data-layout="" data-size=""> */}
                            <a 
                                className="fb-xfbml-parse-ignore game__icon game__fb"
                                target="_blank" 
                                rel="noreferrer"  
                                href={'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(`https://burgerburgernotaburger.com?result=${score}%2F&amp;src=sdkpreparse`)}
                            />
                        {/* </div> */}
                            <a 
                                className="game__icon game__x"
                                target="_blank" 
                                rel="noreferrer"  
                                href={'http://www.twitter.com/share?url=' + encodeURIComponent(`https://burgerburgernotaburger.com?result=${score}`) + '&text=' + encodeURIComponent(`I scored ${score} on Burger, Burger, Not A Burger. Can you beat it?`)} 
                            />
                    </div>

                    <h2 className='game__leaderboard__title'>Leaderboard</h2>

                    <div className='game__leaderboard'>

                        {leaderboard.map((entry, index) => (

                            <div  className='game__leaderboard__row' key={index}>
                                <span className='light'>{index + 1}</span>
                                <span className='left'>{entry.name}</span>
                                <span>{entry.score}</span> 
                            </div>
                        ))}
                    </div>

                    <p>Music by Marcus Darc <a className='purple' href='https://thedarcone.com' target='_blank'>www.thedarcone.com</a></p>

                </Modal>
            }
            {currentObject && (
                <FlyingObject
                    key={currentObject.key}
                    item={currentObject}
                    onClick={handleObjectClick}
                    onAnimationComplete={handleAnimationComplete}
                    speed={speedRef.current}
                />
            )}

            {resultModalActive &&
                <Modal onClose={() => setResultModalActive(false)} size='small'>

                    <h1 className='resultMessage'>I scored {result}!<br/>How many can you get?</h1>

                    <Button
                        colour='purple'
                        label='Start Game'
                        onClick={() => {
                            setGameRunning(true);
                            setResultModalActive(false);
                        }}
                        style={{margin: '0 auto 20px auto'}}
                    />
                </Modal>
            }
        </div>
    );
};

export default Game;
